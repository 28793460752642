<template>
  <div class="main">
    <div class="left">
      <img src="../assets/font.png" alt="" />
    </div>
    <div class="right">
      <div>
        <p>注重美学与功能的平衡并进。试图将商业逻辑与美学兼顾。
          基于严谨的商业逻辑和富有创造力的设计理念，为品牌提供理性与感性的碰撞。以品牌战略为核心，为客户提供从策划到平面、摄影、影视、空间的整体服务方案。
        </p>
      </div>
      <p>
        Pay attention to the balance between aesthetics and function. Try to give consideration to business logic and
        aesthetics. Based on rigorous business logic and creative design concept, provide rational and emotional
        collision for the brand. With brand strategy as the core, we provide customers with an overall service plan from
        planning.
      </p>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped lang="less">
.main {
  display: flex;
  justify-content: space-between;
  padding: 50px 70px;

  .left {
    img {
      width: 350px;
      margin: 0 0 0 0;
    }
  }

  .right {
    margin-top: -20px;;
    font-size: 17px;

    div {
      margin-bottom: .05rem;
    }

    p {
      line-height: 28px;
    }

    width: 700px;
  }
}

/* 屏幕宽度大于1900*/
@media only screen and (min-width:1900px) {
.main {
    display: flex;
    padding: 80px 90px;
    .left {
      img {
        width: 450px;
        margin: 0;
      }
    }
    .right {
      font-size: 20px;

      width: 800px;
    p {
      line-height: 34px;
    }
    }
  }

}

/* 屏幕宽度大于1600并且小于1899 */
@media only screen and (min-width:1600px) and (max-width:1899px) {
  .main {
    display: flex;
    padding: 60px 80px;
    .left {
      img {
        width: 400px;
        margin: 0;
      }
    }
    .right {
      font-size: 18px;

       width: 750px;
    p {
      line-height: 31px;
    }
    }
  }


}

/* 屏幕宽度大于1100并且小于1400 */
@media only screen and (min-width:1100px) and (max-width:1399px) {
  .main {
    display: flex;
    padding: 45px 50px;
    .left {
      img {
        width: 340px;
        margin: -0px 0 0 0;
      }
    }

    .right {
      font-size: 15px;

       width: 600px;
    p {
      line-height: 28px;
    }

      
    }
  }
}

/* 屏幕宽度大于768并且小于1100 */
@media only screen and (min-width:768px) and (max-width:1099px) {

  .main {
    display: flex;
    padding: 30px 40px;
    .left {
      img {
        width: 320px;
        margin: -0px 0 0 0;
      }
    }

    .right {
      font-size: 13px;

       width: 370px;
    p {
      line-height: 28px;
    }
    }
  }
}

/* 屏幕宽度小于768 */
@media only screen and (max-width:768px) {
  .main {
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 30px;

    .left {
      img {
        width: 80%;
      }
    }

    .right {
      width: 100%;
      margin-top: 18px;
      font-size: 15px;
      line-height: 18px;
    }
  }
}
</style>