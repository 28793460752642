<template>
  <div class="phoneNavFrame">
    <div class="phoneNav" @click="changeNav">
      <i :class="navStatus ? 'one' : ''" :style="navLineColor"></i>
      <i :class="navStatus ? 'two' : ''" :style="navLineColor"></i>
      <i :class="navStatus ? 'three' : ''" :style="navLineColor"></i>
    </div>
    <transition name="pullDown">
      <ul class="NavList" v-if="navStatus" :style="navBackground">
        <li v-for="(item, index) in list" :key="index" @click="jump(index)">{{ item }}</li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: "phoneNav",
  data() {
    return {
      navStatus: false,
      list: [
        'Home', 'Works', 'Followind', 'Studio', 'Contact'
      ],
      navBackground: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
      },
      navLineColor: {
        backgroundColor: '#ffffff'
      }
    };
  },
  methods: {
    changeNav() {
      this.navStatus = !this.navStatus
    },
    jump(e) {
      switch (e) {
        case 0:
          this.$router.push({
            path: '/'
          })
          break;
        case 1:
          this.$router.push({
            path: '/work'
          })
          break;
          case 2:
          this.$router.push({
            path: '/followind'
          })
          break;
          // case 3:
          // this.$router.push({
          //   path: '/studio'
          // })
          // break;
          case 4:
          this.$router.push({
            path: '/contact'
          })
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    let pageIndex = this.$route.fullPath;
    if(pageIndex === '/studio' ) {
      this.navBackground.backgroundColor = 'rgba(255,255,255,.5)'
    }
    if(pageIndex === '/followind' || pageIndex === '/work' || pageIndex === '/contact') {
      this.navLineColor.backgroundColor = '#838383'
    }
  }
};
</script>

<style scoped lang="less">
.phoneNav {
  position: absolute;
  right: 0;
  top: -10px;
  cursor: pointer;
  margin: 17px 10px;
  z-index: 100000;

  i {
    margin: 10px 0;
    display: block;
    height: 1px;
    width: 30px;
    transition: all .5s;
    transform-origin: 0 0;

  }

  .one {
    transform: rotate(45deg);
  }

  .two {
    opacity: 0;
  }

  .three {
    transform: rotate(-45deg);
  }

}

.NavList {
  padding: 0;
  margin: 0;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 0;

  // 效果1
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #fff;
    height: 50px;
    margin: 0 10px;
    // border-bottom: 1px solid #fff;
  }
}

.pullDown-enter-active,
.pullDown-leave-active {
  transition: all .5s;
}

.pullDown-enter,
.pullDown-leave-to {
  opacity: 0;
  max-height: 0;
}

.pullDown-enter-to,
.pullDown-leave {
  max-height: 250px;
}
</style>