<template>
    <div class="footer">
        <div class="copyright">
            <span style="padding-right:8px">© 2022 Followind Studio All Right Seserved.</span>  <span class="code" @click="jump" style="cursor:pointer">闽ICP备2022010578号-1</span>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        jump() {
            window.open('https://beian.miit.gov.cn', '_blank')
        }
    }
}
</script>

<style scoped lang="less">
.code{
    font-weight: bold;
}
.footer {
    padding: 50px 70px;

    .copyright {
        font-size: 14px;
        line-height: 30px;
        font-weight: 700;

        .code {
            font-weight: 400;
        }

    }
}

/* 宽度大于1900*/
@media only screen and (min-width:1900px) {
    .footer {
    padding: 50px 90px;
        .copyright {
        font-size: 17px;
        line-height: 30px;}
}
}

/* 屏幕宽度大于1600并且小于1899 */
@media only screen and (min-width:1600px) and (max-width:1899px) {
        .footer {
    padding: 50px 80px;
            .copyright {
        font-size: 15px;
        line-height: 30px;}
}
}

/* 宽度大于1100并且小于1400 */
@media only screen and (min-width:1100px) and (max-width:1399px) {
    .footer {
    padding: 50px 50px;
                .copyright {
        font-size: 13px;
        line-height: 30px;}
}
}

/* 宽度大于768并且小于1100 */
@media only screen and (min-width:768px) and (max-width:1099px) {
    .footer {
    padding: 40px 40px;
                .copyright {
        font-size: 12px;
        line-height: 25px;}
}

}

/* 宽度小于768 */
@media only screen and (max-width:768px) {
    .footer {
        padding: 0px 30px 40px 30px;

        .copyright {
            display: flex;
            flex-direction: column-reverse;
            font-size: 13px;
            font-weight: 700;
        }
    }

}
</style>>
