var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{ref:"mySwiper",staticClass:"swiper-container PCBanner"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{staticClass:"swiper-lazy",attrs:{"data-src":item.url,"alt":"","data-index":index}}),_vm._m(0,true)])}),0),_vm._m(1),_vm._m(2)]),_c('div',{ref:"phoneSwiper",staticClass:"swiper-container phoneBanner"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.phoneList),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{staticClass:"swiper-lazy",attrs:{"data-src":item.url,"alt":"","data-index":index}}),_vm._m(3,true)])}),0),_c('div',{staticClass:"swiper-pagination"}),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-lazy-preloader"},[_c('img',{attrs:{"src":require("../assets/loading2.gif"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-button-prev"},[_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("../assets/swiperLeft.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-button-next"},[_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("../assets/swiperRight.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-lazy-preloader"},[_c('img',{attrs:{"src":require("../assets/loading2.gif"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-button-prev"},[_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("../assets/swiperLeft.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-button-next"},[_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("../assets/swiperRight.png")}})])])
}]

export { render, staticRenderFns }