import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import('../views/Work.vue')
  },
  {
    path: '/workDetail1',
    name: 'workDetail1',
    component: () => import('../views/WorkDetail1.vue')
  },
  {
    path: '/workDetail2',
    name: 'workDetail2',
    component: () => import('../views/WorkDetail2.vue')
  },
  {
    path: '/workDetail3',
    name: 'workDetail3',
    component: () => import('../views/WorkDetail3.vue')
  },
  {
    path: '/workDetail4',
    name: 'workDetail4',
    component: () => import('../views/WorkDetail4.vue')
  },
  {
    path: '/workDetail5',
    name: 'workDetail5',
    component: () => import('../views/WorkDetail5.vue')
  },
  {
    path: '/workDetail6',
    name: 'workDetail6',
    component: () => import('../views/WorkDetail6.vue')
  },
  {
    path: '/workDetail7',
    name: 'workDetail7',
    component: () => import('../views/WorkDetail7.vue')
  },
  {
    path: '/workDetail8',
    name: 'workDetail8',
    component: () => import('../views/WorkDetail8.vue')
  },
  {
    path: '/workDetail9',
    name: 'workDetail9',
    component: () => import('../views/WorkDetail9.vue')
  },
  {
    path: '/workDetail10',
    name: 'workDetail10',
    component: () => import('../views/WorkDetail10.vue')
  },
  {
    path: '/workDetail11',
    name: 'workDetail11',
    component: () => import('../views/WorkDetail11.vue')
  },
  {
    path: '/workDetail12',
    name: 'workDetail12',
    component: () => import('../views/WorkDetail12.vue')
  },
  {
    path: '/workDetail13',
    name: 'workDetail13',
    component: () => import('../views/WorkDetail13.vue')
  },
  {
    path: '/workDetail14',
    name: 'workDetail14',
    component: () => import('../views/WorkDetail14.vue')
  },
  {
    path: '/workDetail15',
    name: 'workDetail15',
    component: () => import('../views/WorkDetail15.vue')
  },
  {
    path: '/workDetail16',
    name: 'workDetail16',
    component: () => import('../views/WorkDetail16.vue')
  },
  {
    path: '/workDetail17',
    name: 'workDetail17',
    component: () => import('../views/WorkDetail17.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/followind',
    name: 'Followind',
    component: () => import('../views/Followind2.vue')
  },
  {
    path: '/yang',
    name: 'Yang',
    component: () => import('../views/yang.vue')
  },
  {
    path: '/yangDetail',
    name: 'yangDetail',
    component: () => import('../views/yangDetail.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  next();
}); 

export default router
