<template>
  <transition name="nav">
    <div :class="['nav']" ref="nav" v-if="navHide" :style="NavColor">
      <div class="logo" @click="jumpIndex" >
        <img :src="logoPath" alt="" @click="jumpIndex" />
      </div>
      <div class="phoneLogo" @click="jumpIndex" >
        <img :src="phoneLogoPath" alt="" @click="jumpIndex" />
      </div>
      <ul
        :class="[
          'header-menu',
          colorOne ? 'colorOne' : '',
          colorTwo ? 'colorTwo' : '',
          colorThree ? 'colorThree' : '',
          colorFour ? 'colorFour' : '',
        ]"
      >
        <li v-for="(item, index) in menuList" :key="index" @click="jump(index)">
          <span class="font-english animate__animated animate__flipInX">
            {{ item.E }}
          </span>
          <span class="font-chinese animate__animated animate__flipInX">
            {{ item.C }}
          </span>
        </li>
      </ul>
      <phoneNav class="phoneNav"></phoneNav>
    </div>
  </transition>
</template>

<script>
import phoneNav from "./phoneNav.vue";
export default {
  components: {
    phoneNav,
  },
  name: "Nav",
  data() {
    return {
      NavColor: {},
      menuList: [
        {
          E: "Home",
          C: "首页",
        },
        {
          E: "Works",
          C: "作品",
        },
        {
          E: "Followind",
          C: "观点",
        },
        {
          E: "Studio",
          C: "漾",
        },
        {
          E: "Contact",
          C: "联系",
        },
      ],
      logoPath: require("@/assets/logo.png"),
      phoneLogoPath: require("@/assets/phoneLogo.png"),
      colorOne: false,
      colorTwo: false,
      colorThree: false,
      colorFour: false,
      navHide: true,
      topIcon: false,
      navShow: true,
      prevTop: 0,
      nowTop: 0,
    };
  },
  methods: {
    jump(index) {
      switch (index) {
        case 0:
          this.$router.push("/");
          break;
        case 1:
          this.$router.push({
            path: "/work",
          });
          break;
        case 2:
          this.$router.push({
            path: "/followind",
          });
          break;
        case 3:
          this.$router.push({
            path: "/yang",
          });
          break;
        case 4:
          this.$router.push({
            path: "/contact",
          });
          break;
        default:
          break;
      }
    },
    jumpIndex() {
      this.$router.push({
        path: "/",
      });
    },
    logoChange(e) {
      if (e == "/work") {
        this.logoPath = require("@/assets/" + "logo-gray" + ".png"); //logo颜色
        this.phoneLogoPath = require("@/assets/" + "phoneLogo-gray" + ".png"); //手机logo颜色
        this.colorOne = true; //文字颜色
      } else if (e == "/workDetail1") {
        this.logoPath = require("@/assets/" + "logo" + ".png");
        this.phoneLogoPath = require("@/assets/" + "phoneLogo" + ".png");
        this.colorTwo = false;
      } else if (e == "/workDetail1") {
        this.logoPath = require("@/assets/" + "logo-gray" + ".png");
        this.phoneLogoPath = require("@/assets/" + "phoneLogo-gray" + ".png");
        this.colorThree = true;
      }
      else if (e == "/contact") {
        this.logoPath = require("@/assets/" + "logo-gray" + ".png");
        this.phoneLogoPath = require("@/assets/" + "phoneLogo-gray" + ".png");
        this.colorThree = true;
      }
      else if (e == "/followind") {
        this.logoPath = require("@/assets/" + "logo-gray" + ".png");
        this.phoneLogoPath = require("@/assets/" + "phoneLogo-gray" + ".png");
        this.colorThree = true;
      }
      else if (e == "/yang") {
        this.logoPath = require("@/assets/" + "Logo-Yang" + ".png");
        this.phoneLogoPath = require("@/assets/" + "phoneLogo" + ".png");
        this.colorFour = true;
      }
    },
    // handleScrollx(e) {
    //   if (e.wheelDelta < 0) {
    //     this.navHide = false;
    //   } else {
    //     this.navHide = true;
    //   }
    // },
    scrollMove() {
      this.prevTop = this.nowTop;
      this.nowTop = document.documentElement.scrollTop;
      if (this.prevTop < this.nowTop) {
        this.navHide = false;
      } else {
        this.navHide = true;
      }
      if (window.pageYOffset == 0) {
        this.navHide = true;
      }
    },
  },
  mounted() {
    let pageIndex = this.$route.fullPath;
    this.logoChange(pageIndex);
    // window.addEventListener("mousewheel", this.handleScrollx, true);
    window.addEventListener("scroll", this.scrollMove, true);
  },
  computed: {
    ColorObj() {
      //获取vuex中的nav颜色
      return this.$store.state.NavColor;
    },
    LogoObj() {
      //获取vuex中的logo名
      return this.$store.state.logoPath;
    },
    phoneLogoObj() {
      return this.$store.state.phoneLogoPath;
    },
  },
  watch: {
    ColorObj: {
      handler(newVal) {
        this.NavColor = newVal;
      },
      immediate: true, //刷新加载 立马触发一次handler
      deep: true,
    },
    LogoObj(newVal) {
      this.logoPath = require("@/assets/" + newVal + ".png");
    },
    phoneLogoObj(newVal) {
      this.phoneLogoPath = require("@/assets/" + newVal + ".png");
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  margin-top: 0px;
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 10000;

  .phoneNav {
    display: none;
  }
  .logo {
    margin: 30px 0.2rem;
    cursor: pointer;
    img {
      width: 120px;
      cursor: pointer;
    }
  }

  .phoneLogo {
    display: none;
    cursor: pointer;}

  .header-menu {
    position: fixed;
    right: 0.2rem;
    z-index: 10000;
    display: flex;
    padding-right: 0px;
    cursor: pointer;

    li {
      width: 80px;
      text-align: center;
      margin-left: 45px;
      font-size: 14px;
    }

    .font-chinese {
      display: none;
      animation-duration: 0.8s; //动画持续时间
    }

    .font-english {
      animation-duration: 0s; //动画持续时间
    }

    li:hover {
      .font-english {
        display: none;
      }

      .font-chinese {
        display: block;
      }
    }
  }
}

// .navFlip-enter-active ,.navFlip-leave-active{
//  transition: all 5s
// }
.colorOne {
  color: rgb(71, 71, 71) !important;
}

.colorTwo {
  color: #666666 !important;
}

.colorThree {
  color: #310101 !important;
}

.colorFour{
  color: #fff !important;
}

/* 宽度大于1900*/
@media only screen and (min-width: 1900px) {
  .nav {
    margin: 10px 0 0 0 !important;
    .logo {
      img {
        width: 140px !important;
      }
    }

    .header-menu {
      li {
        margin-top: 10px !important;
        font-size: 17px !important;
      }
    }
  }
}

/* 屏幕宽度大于1600并且小于1899 */
@media only screen and (min-width: 1600px) and (max-width: 1899px) {
  .logo {
    img {
      width: 130px !important;
    }
  }

  .header-menu {
    li {
      font-size: 15px !important;
      margin-left: 55px !important;
    }
  }
}

/* 宽度大于1100并且小于1400 */
@media only screen and (min-width: 1100px) and (max-width: 1399px) {
  .nav {
    margin-top: 0px;

    .logo {
      img {
        width: 110px !important;
      }
    }

    .header-menu {
      li {
        font-size: 13px !important;
        width: 48px !important;
        text-align: center !important;
      }
    }

    .phoneNav {
      display: none;
    }
  }
}

/* 宽度大于768并且小于1100 */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .nav {
    margin-top: -10px;

    .logo {
      img {
        width: 100px !important;
      }
    }

    .header-menu {
      li {
        font-size: 13px !important;
        width: 40px !important;
        text-align: center !important;
      }
    }

    .phoneNav {
      display: none;
    }
  }
}

/* 宽度小于768 */
@media only screen and (max-width: 768px) {
  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    z-index: 10000;

    .logo {
      display: none;
    }

    .phoneLogo {
      margin: 15px 18px;
      display: block !important;

      img {
        width: 120px;
        cursor: pointer;
        
      }
    }

    .header-menu {
      display: none !important;
      position: fixed;
      right: 0;
      z-index: 10000;
      display: flex;
      padding-right: 0.3rem;
      margin: 30px 0 0 0;

      li {
        padding: 0px 0px 0px 60px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        cursor: pointer;
      }
    }

    .revert {
      color: #666666 !important;
    }

    .phoneNav {
      display: block !important;
    }
  }
}

// 导航根据scrollTop消失
.nav-enter-active {
  transition: all 0.5s ease;
}

.nav-leave-active {
  transition: all 0.5s ease;
}

.nav-enter,
.nav-leave-to {
  opacity: 0;
  max-height: 200px;
}

.nav-enter-to,
.nav-leave {
  opacity: 1;
  max-height: 200px;
}

// 元素Y轴偏移
// .navCon-enter-active, .navCon-leave-active{
//   transition: all .8s ease;
// }
// .navCon-enter, .navCon-leave-to{
//   transform: translateX(0);
// }
// .navCon-enter-to, .navCon-leave{
//   transform: translateX(100px);
// }
</style>