<template>
  <div class="banner">
    <div class="swiper-container PCBanner" ref="mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="index">
          <img :data-src="item.url" alt="" :data-index="index" class="swiper-lazy" />
          <div class="swiper-lazy-preloader">
            <img src="../assets/loading2.gif" alt="">
          </div>
        </div>
      </div>
      <div class="swiper-button-prev">
        <div class="arrow"><img src="../assets/swiperLeft.png" /></div>
      </div>
      <div class="swiper-button-next">
        <div class="arrow"><img src="../assets/swiperRight.png" /></div>
      </div>
    </div>

    <div class="swiper-container phoneBanner" ref="phoneSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in phoneList" :key="index">
          <img :data-src="item.url" alt="" :data-index="index" class="swiper-lazy" />
          <div class="swiper-lazy-preloader">
            <img src="../assets/loading2.gif" alt="">
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev">
        <div class="arrow"><img src="../assets/swiperLeft.png" /></div>
      </div>
      <div class="swiper-button-next">
        <div class="arrow"><img src="../assets/swiperRight.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
export default {
  name: "Banner",
  data() {
    return {
      list: [

        {
          url: require('../assets/banner/14.jpg'),
        },
        {
          url: require('../assets/banner/31.jpg'),
        },
        {
          url: require('../assets/banner/3.jpg'),
        },
        // {
        //   url: require('../assets/banner/1.jpg'),
        //},
        // {
        //   url: require('../assets/banner/13.jpg'),
        // },
        {
          url: require('../assets/banner/32.jpg'),
        },
        {
          url: require('../assets/banner/2.jpg'),
        },
        {
          url: require('../assets/banner/15.jpg'),
        },
        {
          url: require('../assets/banner/21.jpg'),
        },
        {
          url: require('../assets/banner/9.jpg'),
        },
        {
          url: require('../assets/banner/29.jpg'),
        },
        {
          url: require('../assets/banner/30.jpg'),
        },
      ],
      phoneList: [
        {
          url: require('../assets/banner/phoneBanner1.jpg')
        },
        {
          url: require('../assets/banner/phoneBanner8.jpg')
        },
        {
          url: require('../assets/banner/phoneBanner4.jpg')
        },
        // {
        //   url: require('../assets/banner/phoneBanner2.jpg')
        // },

        // {
        //   url: require('../assets/banner/phoneBanner9.jpg')
        // },
        {
          url: require('../assets/banner/phoneBanner3.jpg')
        },
        {
          url: require('../assets/banner/phoneBanner5.jpg')
        },
        {
          url: require('../assets/banner/phoneBanner6.jpg')
        },
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.swiperInit()
      this.PhoneSwiperInit()
    })
    
  },
  methods: {
    swiperInit() {
        new Swiper(".PCBanner", {
        //mousewheel: true, //滚轮
        speed: 1000,
        observer: true,
        observeParents: true,
        // autoplay: { //自动开始
        // 	delay: 3000, //时间间隔
        // 	disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        // },
        lazy: {
          // loadPrevNext: true,
          loadOnTransitionStart: true
        },
        loop: true, // 循环模式选项
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          tap: (e) => {
            let index = e.target.getAttribute("data-swiper-slide-index");
            switch (index) {
              case "0":
                this.$router.push("/workDetail5");
                break;
              case "1":
                this.$router.push("/workDetail8");
                break;
                              case "2":
                this.$router.push("/");
                break;
                             case "3":
                this.$router.push("/workDetail8");
                break;
                              case "4":
                this.$router.push("/workDetail2");
                break;
                              case "5":
                this.$router.push("/workDetail6");
                break;
                              case "6":
                this.$router.push("/");
                break;
                              case "7":
                this.$router.push("/workDetail14");
                break;
                              case "8":
                this.$router.push("/workDetail5");
                break;
                              case "9":
                this.$router.push("/");
                break;
                              case "10":
                this.$router.push("/");
                break;
              default:
                break;
            }
          },
          slideChange: () => {
            let index = this.$refs.mySwiper.swiper.realIndex; //当前图片索引
            switch (index) {
              case 0:
                this.$store.commit("changeIndex", "#ffffff");
                this.$store.commit("changeLogo", "logo");
                this.$store.commit("changephoneLogo", "phoneLogo");
                break;
              case 1:
                this.$store.commit("changeIndex", "#444444");
                this.$store.commit("changeLogo", "logo-gray");
                this.$store.commit("changephoneLogo", "phoneLogo-gray");
                break;
              case 2:
                this.$store.commit("changeIndex", "#888888");
                break;
              case 3:
                this.$store.commit("changeIndex", "#3F1512");
                this.$store.commit("changeLogo", "logo");
                break;
              case 4:
                this.$store.commit("changeIndex", "#ffffff");

                break;
              case 5:
                this.$store.commit("changeIndex", "#888888");
                break;
              case 6:
                this.$store.commit("changeIndex", "#888888");
                break;
              case 7:
                this.$store.commit("changeIndex", "#888888");
                break;
              //除了控制的index图片之外的默认颜色
              default:
                this.$store.commit("changeIndex", "#888888");
                break;
            }
          },
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    PhoneSwiperInit() {
        new Swiper(".phoneBanner", {
        //mousewheel: true, //滚轮
        speed: 1000,
        observer: true,
        observeParents: true,
        // autoplay: { //自动开始
        // delay: 3000, //时间间隔
        // disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        // },
        lazy: {
          // loadPrevNext: true,
          loadOnTransitionStart: true
        },
        loop: true, // 循环模式选项
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          tap: (e) => {
            let index = e.target.getAttribute("data-swiper-slide-index");
            switch (index) {
              case "0":
                this.$router.push("/");
                break;
              case "1":
                this.$router.push("/");
                break;
                case "2":
                this.$router.push("/");
                break;
                case "3":
                this.$router.push("/");
                break;
                case "4":
                this.$router.push("/");
                break;
                case "5":
                this.$router.push("/");
                break;
                case "6":
                this.$router.push("/");
                break;
                case "7":
                this.$router.push("/");
                break;
              default:
                break;
            }
          },
          slideChange: () => {
            let index = this.$refs.phoneSwiper.swiper.realIndex; //当前图片索引
            console.log(index)
            switch (index) {
              case 0:
                this.$store.commit("changeIndex", "#ffffff");
                this.$store.commit("changeLogo", "logo");
                this.$store.commit("changephoneLogo", "phoneLogo");
                break;
              case 1:
                this.$store.commit("changeIndex", "#444444");
                this.$store.commit("changeLogo", "logo-gray");
                this.$store.commit("changephoneLogo", "phoneLogo-gray");
                break;
              case 2:
                this.$store.commit("changeIndex", "#888888");
                break;
              case 3:
                this.$store.commit("changeIndex", "#3F1512");
                this.$store.commit("changeLogo", "logo");
                break;
              case 4:
                this.$store.commit("changeIndex", "#ffffff");

                break;
              case 5:
                this.$store.commit("changeIndex", "#888888");
                break;
              case 6:
                this.$store.commit("changeIndex", "#888888");
                break;
              case 7:
                this.$store.commit("changeIndex", "#888888");
                break;
              //除了控制的index图片之外的默认颜色
              default:
                this.$store.commit("changeIndex", "#888888");
                break;
            }
          },
        },
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
      });
    }
  }
};
</script>

<style lang="less" scoped>
.phoneBanner {
  display: none;
}

.banner {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  cursor: pointer;

  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 5rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

/* 宽度大于1900*/
@media only screen and (min-width:1900px) {}

/* 宽度大于1100并且小于1400 */
@media only screen and (min-width:1100px) and (max-width:1399px) {}

/* 宽度大于768并且小于1100 */
@media only screen and (min-width:768px) and (max-width:1099px) {}

/* 宽度小于768 */
@media only screen and (max-width:768px) {
  .swiper-slide {
    width: 100%;
    height: 13rem !important;
  }

  .phoneBanner {
    display: block;
  }
  /deep/ .swiper-button-prev{
    display: none!important;
  }
  /deep/ .swiper-button-next{
    display: none!important;
  }

  .PCBanner {
    display: none;
  }
  // 分页底色
  /deep/ .swiper-pagination-bullet{
    opacity: 1;
    background: rgba(0,0,0,.05)
  }

  // 分页选中
  /deep/ .swiper-pagination-bullet-active{
    background: #257347;
  }
}
</style>

