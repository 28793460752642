<template>
  <div class="home">
      <div>
        <Nav></Nav>
        <Banner></Banner>
        <Main></Main>
        <Footer></Footer>
      </div>
    <transition name="mask">
      <div class="mask" v-if="show">
          <img src="@/assets/loading.gif" alt="">
      </div>
    </transition>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Nav from '../components/Nav'
import Banner from '../components/Banner'
import Main from '../components/Main'
export default {
  components:{
    Nav,
    Banner,
    Main,
    Footer
  },
    data() {
        return {
          show: true
        }
    },
    methods:{
      
    },
    created() {
      let data = sessionStorage.getItem("maskShow");
      if (data == null) {
        document.body.parentNode.style.overflowY = 'hidden'
        sessionStorage.setItem("maskShow", true);
      }
      if (data == "false") {
        this.show = false;
      }
    },
    mounted() {
      let data = sessionStorage.getItem("maskShow");
      if(data == 'true') {
         this.show = true
         setTimeout(() => {
          this.show = false
          sessionStorage.maskShow = 'false'
          document.body.parentNode.style.overflowY = 'auto'
         },3000)
      }
    }
}
</script>

<style scoped lang="less">
 .home{
   margin: 0;
 }
 .mask{
  position:absolute;
  top: 0;
  left: 0;
  z-index: 99999999999;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    transform: scale(.5);
  }
 }
 .mask-enter-active,.mask-leave-active{
  transition: all .5s linear;
 }
 .mask-enter-to,.mask-leave{
  opacity: 1;
 }
 .mask-enter,.mask-leave-to{
  opacity: 0;
 }
</style>