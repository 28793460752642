<template>
  <div id="app">
    <div :class="['topIcon', topIcon ? 'show' : '']" @click="goTop">
      <img class="topImg" src="./assets/topimg.svg" alt="" />
    </div>
    <router-view />
  </div>
</template>

<script>

export default {
  data() {
    return {
      topIcon: false,
      prevTop: 0,
      nowTop: 0
    };
  },
  methods: {
    topPosition() {
      if (window.pageYOffset > 300) {
        this.topIcon = true;
      } else {
        this.topIcon = false;
      }
    },
    goTop() {
      let timer = setInterval(() => {
        let currentPosition = document.documentElement.scrollTop;
        currentPosition -= 50;
        if (currentPosition > 0) {
          window.scrollTo(0, currentPosition);
        } else {
          window.scrollTo(0, 0);
          clearInterval(timer);
        }
      }, 1);
    },
  },
  mounted() {
    const that = this
    window.document.oncontextmenu = function () {
      that.$message('Prohibit Copying');
      return false;
    }
    window.addEventListener("scroll", this.topPosition, true);
  },
};
</script>
<style lang="less">
body,
html {
  padding: 0;
  margin: 0;
  font-family: 'sh';
}
img{
   pointer-events:none;
}

body {
  position: relative;
}
ul{
  padding: 0;
}
ul,
li {
  list-style: none;
}
::selection {
  background: #007c64;
  color: #fff;
}
::-moz-selection {
  background: #007c64;
  color: #fff;
}

.topIcon {
  opacity: 0;
  cursor: pointer;
  right: 30px;
  bottom: 20px;
  position: fixed;
  font-size: 50px;
  transition: all 1s;
  z-index: 9999999;
}
.show {
  opacity: 1;
}
.topImg {
  width: 40px;
}
@font-face {
  font-family: sh;
  src: url('./assets/font/SourceHanSansCN-Normal.otf');
}


.el-message{
  display: flex;
  justify-content: center;
  color: #ccc!important;
  background-color: #fff!important;
}
.el-message__icon{
  display: none!important;
}
.el-message__content{
  color: #ccc!important;
}
</style>
