import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
//全部
let allImgList = [

  {
    url: require('@/assets/banner/35.jpg'),
    bigCon: '心乐雅士-素叶灵芽',
    smallCon: ' 白牡丹包装',
   path: '/workDetail15'
  },
  {
    url: require('@/assets/banner/37.jpg'),
    bigCon: 'Followind 风宋',
    smallCon: ' 更新中',
    // path: '/'
  },
  {
    url: require('@/assets/banner/39.jpg'),
    bigCon: '瑞器-狻猊',
    smallCon: ' 更新中',
    path: '/workDetail11'
  },
  {
    url: require('@/assets/banner/34.jpg'),
    bigCon: '官耳',
    smallCon: ' 更新中',
    // path: '/'
  },
  {
    url: require('@/assets/banner/31.jpg'),
    bigCon: '心乐雅士-云岚系列[更新中..]',
    smallCon: ' 更新中',
    // path: '/'
  },

  {
    url: require('@/assets/banner/32.jpg'),
    bigCon: '心乐雅士-云岚系列',
    smallCon: ' 岚腾雾秀，云蒸霞蔚',
   path: '/workDetail8'
   },
  //  //{
  //   url: require('@/assets/banner/38.jpg'),
  //   bigCon: '黄胜记-姜母鸭',
  //   smallCon: ' 更新中',
  //   // path: '/' 
  // },
  // {
  //   url: require('@/assets/banner/36.jpg'),
  //   bigCon: '黄胜记-姜母鸭',
  //   smallCon: ' 更新中',
  //   // path: '/'
  // },
  {
    url: require('@/assets/banner/101.jpg'),
    bigCon: '黄胜记松塔包装',
    smallCon: ' 鼓浪屿记忆',
    path: '/workDetail7'
  },
  {
    url: require('@/assets/banner/14.jpg'),
    bigCon: '西有好物 2021品牌升级 [更新中..]',
    smallCon: ' NEXT TO SEE YOU',
    // path: '/workDetail1'
  },
  // {
  //   url: require('@/assets/banner/40.jpg'),
  //   bigCon: '国鲍荟',
  //   smallCon: ' 更新中',
  //   // path: '/'
  // },
  //  {
  //    url: require('@/assets/banner/1.jpg'),
  //    bigCon: '物伴 WOOFUN',
  //    smallCon: '八马旗下,专注于茶生活人文美学',
  //    path: '/workDetail1'
  //  },
  {
    url: require('@/assets/banner/2.jpg'),
    bigCon: '鼓浪嶼黃勝記伴系列手禮',
    smallCon: '厦门百年老字号',
    path: '/workDetail2'
  },
  {
    url: require('@/assets/banner/3.jpg'),
    bigCon: '南日小渔 NANRI·FISHERGUY [更新中..]',
    smallCon: '融合创新的方式探索及呈现海岛的生命力',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/100.jpg'),
    bigCon: '心乐雅士 品鉴装',
    smallCon: ' 更新中',
    path: '/workDetail4'
  },
  {
    url: require('@/assets/banner/29.jpg'),
    bigCon: '天夏系列',
    smallCon: '在西部广袤的大地上 自然已经为您做好了选择',
    path: '/workDetail5'
  },
  {
    url: require('@/assets/banner/15.jpg'),
    bigCon: '雅志乐林泉-系列插画',
    smallCon: '文化之间的碰撞将呈现出耳目一新的品牌哲学',
    path: '/workDetail6'
  },
  {
    url: require('@/assets/banner/4.jpg'),
    bigCon: '喜饭 [更新中..]',
    smallCon: '出口日本高端米饭供应商',
    // path: '/workDetail1'
  },
  // {
  //   url: require('@/assets/banner/13.jpg'),
  //   bigCon: '天籁琴筝 TIANLAI [更新中..]',
  //   smallCon: '诠释更为年轻态的东方视听美学',
  //   // path: '/workDetail1'
  // },
  {
    url: require('@/assets/banner/25.jpg'),
    bigCon: '一米茶 [更新中..]',
    smallCon: '一米，是我和记忆力茶果园的距离',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/24.jpg'),
    bigCon: 'Daogrs',
    smallCon: '体验纯正意大利设计美学带来的震撼',
    path: '/workDetail10'
  },
  {
    url: require('@/assets/banner/8.jpg'),
    bigCon: '美良口腔 Melauoh [更新中..]',
    smallCon: 'Warm with attitude,just for every better smile',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/5.jpg'),
    bigCon: '宝富鸿 BONFOI&HO [更新中..]',
    smallCon: '专业、璀璨',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/6.jpg'),
    bigCon: '黄胜记肉脯包装设计',
    smallCon: '厦门百年老字号',
    path: '/workDetail3'
  },
  {
    url: require('@/assets/banner/9.jpg'),
    bigCon: '百年老字号鼓浪嶼黃勝記品牌升级',
    smallCon: 'Since 1842',
    path: '/workDetail14'
  },
  {
    url: require('@/assets/banner/18.jpg'),
    bigCon: '月桂知秋  MID AYUTUMN [更新中..]',
    smallCon: '中秋月饼礼盒',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/21.jpg'),
    bigCon: '心乐雅士 [更新中..]',
    smallCon: 'You life knows',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/26.jpg'),
    bigCon: '西有好物 品牌设计 2019 [更新中..]',
    smallCon: '西有好物初成长',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/7.jpg'),
    bigCon: '与奇迹同行 RUNING WITH MIRACLES',
    smallCon: '不同寻常 不同凡响 不可多得',
    path: '/workDetail16'
  },
  // {
  //   url: require('@/assets/banner/28.jpg'),
  // },
]
//品牌
let brandImgList = [
  
  {
    url: require('@/assets/banner/34.jpg'),
    bigCon: '官耳',
    smallCon: ' 更新中',
    // path: '/'
  },
  // {
  //   url: require('@/assets/banner/33.jpg'),
  //   bigCon: '国鲍荟',
  //   smallCon: ' 更新中',
  //   // path: '/'
  // },
  {
    url: require('@/assets/banner/14.jpg'),
    bigCon: '西有好物 2021品牌升级 [更新中..]',
    smallCon: ' NEXT TO SEE YOU',
    // path: '/workDetail1'
  },
  //  {
  //    url: require('@/assets/banner/1.jpg'),
  //    bigCon: '物伴 WOOFUN',
  //    smallCon: '八马旗下,专注于茶生活人文美学',
  //    path: '/workDetail1'
  //  },
   {
    url: require('@/assets/banner/3.jpg'),
    bigCon: '南日小渔 NANRI·FISHERGUY [更新中..]',
    smallCon: '融合创新的方式探索及呈现海岛的生命力',
    // path: '/workDetail1'
  },
  // {
  //   url: require('@/assets/banner/13.jpg'),
  //   bigCon: '天籁琴筝 TIANLAI [更新中..]',
  //   smallCon: '诠释更为年轻态的东方视听美学',
  //   // path: '/workDetail1'
  // },
  {
    url: require('@/assets/banner/8.jpg'),
    bigCon: '美良口腔 Melauoh [更新中..]',
    smallCon: 'Warm with attitude,just for every better smile',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/5.jpg'),
    bigCon: '宝富鸿 BONFOI&HO [更新中..]',
    smallCon: '专业、璀璨',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/9.jpg'),
    bigCon: '百年老字号鼓浪嶼黃勝記品牌升级',
    smallCon: 'Since 1842',
    path: '/workDetail14'
  },
  {
    url: require('@/assets/banner/21.jpg'),
    bigCon: '心乐雅士 [更新中..]',
    smallCon: 'You life knows',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/26.jpg'),
    bigCon: '西有好物 品牌设计 2019 [更新中..]',
    smallCon: '西有好物初成长',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/7.jpg'),
    bigCon: '与奇迹同行 RUNING WITH MIRACLES',
    smallCon: '不同寻常 不同凡响 不可多得',
    path: '/workDetail16'
  },
]
//包装
let packIngImgList = [
  {
    url: require('@/assets/banner/35.jpg'),
    bigCon: '心乐雅士-素叶灵芽',
    smallCon: ' 更新中',
    // path: '/'
  },
  // {
  //   url: require('@/assets/banner/38.jpg'),
  //   bigCon: '黄胜记-姜母鸭',
  //   smallCon: ' 更新中',
  //   // path: '/'
  // },
  {
    url: require('@/assets/banner/31.jpg'),
    bigCon: '心乐雅士-云岚系列[更新中..]',
    smallCon: ' 更新中',
    // path: '/'
  },
  {
    url: require('@/assets/banner/32.jpg'),
    bigCon: '心乐雅士-云岚系列[更新中..]',
    smallCon: ' 更新中',
    // path: '/'
  },
  {
    url: require('@/assets/banner/101.jpg'),
    bigCon: '黄胜记松塔包装',
    smallCon: ' 鼓浪屿记忆',
    path: '/workDetail7'
  },
  {
    url: require('@/assets/banner/2.jpg'),
    bigCon: '鼓浪嶼黃勝記伴系列手禮',
    smallCon: '厦门百年老字号',
    path: '/workDetail2'
  },
  {
    url: require('@/assets/banner/100.jpg'),
    bigCon: '心乐雅士 品鉴装',
    smallCon: ' 更新中',
    path: '/workDetail4'
  },
  {
    url: require('@/assets/banner/29.jpg'),
    bigCon: '天夏系列',
    smallCon: '在西部广袤的大地上 自然已经为您做好了选择',
    path: '/workDetail5'
  },
  {
    url: require('@/assets/banner/6.jpg'),
    bigCon: '黄胜记肉脯包装设计',
    smallCon: '厦门百年老字号',
    path: '/workDetail3'
  },
  {
    url: require('@/assets/banner/18.jpg'),
    bigCon: '月桂知秋  MID AYUTUMN [更新中..]',
    smallCon: '中秋月饼礼盒',
    // path: '/workDetail1'
  },
]
//摄影
let photographyImgList = [

]
//空间
let spaceImgList = [
  {
    url: require('@/assets/banner/25.jpg'),
    bigCon: '一米茶 [更新中..]',
    smallCon: '一米，是我和记忆力茶果园的距离',
    // path: '/workDetail1'
  },
  {
    url: require('@/assets/banner/24.jpg'),
    bigCon: 'Daogrs',
    smallCon: '体验纯正意大利设计美学带来的震撼',
    path: '/workDetail10'
  },
]

export default new Vuex.Store({
  state: {
    NavColor: {
      color: '#FFFFFF',
    },
    logoPath: 'logo',
    phoneLogoPath: 'logo',
    workImgList: allImgList
  },
  mutations: {
    changeIndex(state, e) {
      state.NavColor.color = e
    },
    changeLogo(state, e) {
      state.logoPath = e
    },
    changephoneLogo(state, e) {
      state.phoneLogoPath = e
    },
    changeList(state, e) {
      switch (e) {
        case 0:
          state.workImgList = allImgList
          break;
        case 1:
          state.workImgList = brandImgList
          break;
        case 2:
          state.workImgList = packIngImgList
          break;
        case 3:
          state.workImgList = spaceImgList
          break;
        case 4:
          state.workImgList = photographyImgList
          break;
        default:
          break;
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
