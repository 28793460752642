var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"nav"}},[(_vm.navHide)?_c('div',{ref:"nav",class:['nav'],style:(_vm.NavColor)},[_c('div',{staticClass:"logo",on:{"click":_vm.jumpIndex}},[_c('img',{attrs:{"src":_vm.logoPath,"alt":""},on:{"click":_vm.jumpIndex}})]),_c('div',{staticClass:"phoneLogo",on:{"click":_vm.jumpIndex}},[_c('img',{attrs:{"src":_vm.phoneLogoPath,"alt":""},on:{"click":_vm.jumpIndex}})]),_c('ul',{class:[
          'header-menu',
          _vm.colorOne ? 'colorOne' : '',
          _vm.colorTwo ? 'colorTwo' : '',
          _vm.colorThree ? 'colorThree' : '',
          _vm.colorFour ? 'colorFour' : '',
        ]},_vm._l((_vm.menuList),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.jump(index)}}},[_c('span',{staticClass:"font-english animate__animated animate__flipInX"},[_vm._v(" "+_vm._s(item.E)+" ")]),_c('span',{staticClass:"font-chinese animate__animated animate__flipInX"},[_vm._v(" "+_vm._s(item.C)+" ")])])}),0),_c('phoneNav',{staticClass:"phoneNav"})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }